import { Col, Form, Modal, Popconfirm, Row, Spin } from 'antd'
import { AxiosError } from 'axios'
import clsx from 'clsx'
import dayjs from 'dayjs'
import { t } from 'i18next'
import { cloneDeep, isEmpty } from 'lodash'
import moment from 'moment'
import { ReactNode, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { RootState } from '../../../../../app/store'
import SearchIcon from '../../../../../assets/icons/search-stock.svg'
import CSwitch from '../../../../../components/Common/CSwitch/CSwitch'
import Define from '../../../../../constants/define'
import { InputType } from '../../../../../enum/InputType'
import { MODE } from '../../../../../enum/mode'
import { sourceType } from '../../../../../enum/sourceType'
import { focusById } from '../../../../../hook/usePressTab'
import commandeApi from '../../../../../http/commandeApi'
import referenceApi, { EntryFind } from '../../../../../http/referenceApi'
import {
  CommandeResponse,
  Conditionnement,
  InfoActionType,
} from '../../../../../models'
import { CommonError } from '../../../../../models/common/error'
import {
  getCurrentFiltersText,
  isGreatThan,
  isLessThan,
  removeTonesUpperCase,
} from '../../../../../utils'
import { checkSSCC } from '../../../../../utils/barcode'
import ITable from '../../../stockscreen/component/ITable'
import SectionLayout from '../../../stockscreen/component/SectionLayout'
import {
  CInput,
  InvalidProperty,
  NameAndValue,
  ResetProperty,
} from '../../../stockscreen/component/Wrapper'
import {
  ActionParam,
  DataTableParam,
  ForceShowError,
  ForceShowWarning,
  FormatRowCol,
  LineParam,
  ObserveAndTransform,
  OptionType,
  TitleParam,
} from '../../../stockscreen/component/model'
import {
  addFieldToHeadOfHeader,
  addOrChangeValueInLine,
  addOrReplaceRefId,
  checkDluoCmdLinesNotValid,
  checkDupEntriesCmdLines,
  checkDupSSccCmdLines,
  dataIsEmpty,
  disabledAllModal,
  disabledFields,
  enabledAllModal,
  enableField,
  forceError,
  getArticles,
  getIn4FromResultOfSSCC,
  getIndexes,
  getIndexFieldInHeader,
  getLineClass,
  getLineWithSourceFromDataTable,
  getValueFromData,
  getValueFromLine,
  hasAtleastDetailLine,
  mapOriginDataAndSearchData,
  onResetForceShowWaring,
  removeAllDetailLine,
  removeAllDetailLine2,
  removeColsFromHeader,
  removeFieldFromHeader,
  resetAllFieldsToDash,
  selectDefaultReference,
  setActionsToReadyForEditAndDelete,
  setWarningForDluo,
} from './func'
import data from './init-detail-data.json'
import { ReloadOutlined } from '@ant-design/icons'
import define from '../../../../../constants/define'

const cInputProps = {
  className: 'border-children-cell-table text-center',
  requiredMessage: '',
  validates: [],
}
const formatCols: FormatRowCol[] = []

interface ActionProps {
  photos: number
  onChangePhotos: (value: number) => void
  isAllowAddNewLine: boolean

  onClickPlusBtn?(): void

  detailLength: number
  canReplenish: boolean
  sendReplenishMission?: () => void
}

const Action = (props: ActionProps) => {
  const commandeKey = useSelector((state: RootState) => state.selector).data
    .commande
  const onClickPlusBtn = (): void => {
    if (!props?.isAllowAddNewLine) return
    if (props?.onClickPlusBtn) props.onClickPlusBtn()
  }
  const onChange = (data: NameAndValue) => {
    props.onChangePhotos(Number(data.value))
  }

  function getNextFocusIds() {
    let nextId = ''
    for (let i = 0; i < props.detailLength; i++) {
      nextId = `${nextId}, commandereference-${i}, commandeicon-edit-${i}`
    }
    return `${nextId}, ${commandeKey['code-nom-transporter']}, ${commandeKey['code-nom-destinataire']}, ${commandeKey['preparation-commentaire']}, ${commandeKey['Sauvegarder']}`
  }

  return (
    <Row gutter={32} align="middle" className="detail-action">
      <Col className="flex items-center gap-3">
        <span className="form-label">Photo(s):</span>
        <CInput
          {...cInputProps}
          id={commandeKey['photos']}
          previousId={commandeKey['Forcer-la-DLUO']}
          nextId={getNextFocusIds()}
          className="custom-input w-10"
          width={50}
          maxLength={2}
          type={[InputType.NUMBER_INTEGER_POS]}
          onChange={onChange}
          name="photo"
          defaultValue={String(props.photos)}
          validates={[
            {
              message: ' ',
              validateFunction: (value) => isLessThan(Number(value), 21),
              realtime: true,
              order: 0,
            },
          ]}
        />
      </Col>
      {props?.sendReplenishMission ? (
        <div
          onClick={() => props.canReplenish && props.sendReplenishMission?.()}
          className={clsx(
            'h-[30px] rounded-xl px-4 flex items-center gap-x-2',
            props.canReplenish
              ? 'bg-[#E39A4C]  cursor-pointer'
              : 'bg-gray3 cursor-no-drop'
          )}
        >
          <ReloadOutlined className="w-4 h-4 text-[#FFF]" />
          <p className="text-[#FFF] font-semibold">{t('replenish')}</p>
        </div>
      ) : null}
      <Col className="flex items-center gap-3">
        <i
          className={`${
            !props?.isAllowAddNewLine
              ? 'icon-add-plus-disable'
              : 'icon-add-plus'
          }`}
          onClick={onClickPlusBtn}
        ></i>
      </Col>
    </Row>
  )
}

interface DetailSectionProps {
  mode?: MODE
  onChangeData?: (value: DataTableParam[][]) => void
  onCheckValid?: (value: boolean) => void
  alert?: ReactNode
  detailError?: CommonError[]
  initData?: DataTableParam[][]
  dataFromSearch?: DataTableParam[][]
  onChangeInit?: (isInit: boolean) => void
  onChangePhoto?: (photos: number) => void
  onBackToInit?: (isInit: boolean) => void
  onDeleteLine?: (id: string) => void
  valuePhoto?: number
  onChangePhotos: (value: number) => void
  cmdResponse?: CommandeResponse
  onSearchStock?: () => Promise<void>
  isSearch?: boolean
  onChangeForcerDluo?: (value: boolean) => void
  forcerDluo?: boolean
}

const firstLineData = JSON.parse(JSON.stringify(data)) as DataTableParam[]

function DetailSection(props: DetailSectionProps) {
  const commandeKey = useSelector((state: RootState) => state.selector).data
    .commande
  const onSearchReference = (keyword: string): Promise<OptionType[]> => {
    return referenceApi.searchReferenceWithFournisser(keyword).then((res) => {
      const result: OptionType[] = res.data.entry.map((item) => {
        return {
          label: item.ref_nom,
          value: item.ref_nom,
        }
      })
      return result
    })
  }
  const today = dayjs()
  const [maxQteEditing, setMaxQteEditing] = useState<number>()
  const [header, setHeader] = useState<TitleParam[]>([
    {
      label: 'Référence',
      required: true,
      name: 'reference',
      cInputProps: {
        ...cInputProps,
        requiredMessage: ' ',
        maxLength: 20,
        type: [InputType.C_SEARCH],
        width: 200,
        onSearch: onSearchReference,
        invisibleIfChild: true,
        cellClass: 'py-3',
      },
    },
    {
      label: 'Libellé',
      name: 'libelle',
      cInputProps: {
        ...cInputProps,
        requiredMessage: ' ',
        maxLength: 20,
        type: [InputType.ALL_TEXT],
        width: 200,
        invisibleIfChild: true,
      },
    },

    {
      label: 'Lot',
      name: 'lot',
      cInputProps: {
        ...cInputProps,
        requiredMessage: ' ',
        maxLength: 20,
        type: [InputType.ALL_TEXT],
        width: 200,
        format: removeTonesUpperCase,
        textStyleFollowValue: (value: string, line?: LineParam[]) => {
          const source = line?.find((i: any) => i.name === 'source')?.value
          const isManualLot = line?.find(
            (i: any) => i.name === 'is_manual_lot'
          )?.value
          if (source === sourceType.MANUAL && isManualLot === '1')
            return '!font-semibold'
          return ''
        },
      },
    },

    {
      label: 'SSCC',
      name: 'sscc',
      cInputProps: {
        ...cInputProps,
        requiredMessage: ' ',
        maxLength: 18,
        type: [InputType.NUMBER_INTEGER_POS],
        width: 200,
        textStyleFollowValue: (value: string, line?: LineParam[]) => {
          const isManualSSCC = line?.find(
            (i: any) => i.name === 'is_manual_sscc'
          )?.value
          const source = line?.find((i: any) => i.name === 'source')?.value
          if (source === sourceType.MANUAL && isManualSSCC === '1')
            return '!font-semibold'
          return ''
        },
        validates: [
          {
            message: 'Entrée invalide',
            validateFunction: (value: string, line?: LineParam[]) => {
              const source = line?.find((item) => item.name === 'source')?.value
              return source === sourceType.AUTO ? true : checkSSCC(value)
            },
            order: 0,
            realtime: true,
          },
        ],
      },
    },

    {
      label: 'DLUO',
      name: 'dluo',
      cInputProps: {
        ...cInputProps,
        className: cInputProps.className + ' w-26',
        requiredMessage: ' ',
        placeHolder: 'Selectionnez',
        maxLength: 15,
        type: [InputType.DATE],
        width: 200,
        formatOnBlur: true,
        textStyleFollowValue: (value: string, line?: LineParam[]) => {
          let defaultClass = 'text-md w-[150px] text-center'
          const isManualDLUO = line?.find(
            (i: any) => i.name === 'is_manual_dluo'
          )?.value
          const source = line?.find((i: any) => i.name === 'source')?.value

          if (source === sourceType.MANUAL && isManualDLUO === '1')
            defaultClass += ' !font-semibold'
          if (
            dayjs(value, t('data-format')).isBefore(today) &&
            !cmdResponse?.force_dlou
          ) {
            defaultClass += ' text-red'
          }
          return defaultClass
        },
      },
    },

    {
      label: 'Statut',
      name: 'statut',
      cInputProps: {
        ...cInputProps,
        requiredMessage: ' ',
        maxLength: 20,
        type: [InputType.SELECT],
        placeHolder: 'Selectionnez',
        width: 200,
        options: [
          { label: 'Commercialisable', value: 'commercialisable' },
          // {label: 'Blocage usine', value: 'blocage_usine'}
        ],
      },
    },
    {
      label: 'Cdn',
      name: 'cdn',
      required: true,
      cInputProps: {
        ...cInputProps,
        requiredMessage: ' ',
        maxLength: 100,
        placeHolder: 'Selectionnez',
        // formatOnBlur: true,
        type: [InputType.SELECT],
        width: 200,
      },
    },
    {
      label: 'Qte',
      name: 'qte',
      required: true,
      cInputProps: {
        ...cInputProps,
        requiredMessage: ' ',
        maxLength: 5,
        type: [InputType.NUMBER_INTEGER_POS],
        width: 160,
        validates: [
          {
            message: 'Quantité doit être supérieure à 0',
            validateFunction: (value) => isGreatThan(Number(value), 0),
            order: 0,
            realtime: true,
          },
          {
            order: 0,
            validateFunction: (value) => isLessThan(Number(value), 10000 + 1),
            message: 'Max = 10.000',
            realtime: true,
          },
        ],
      },
    },

    {
      label: 'Total pièce',
      name: 'total_piece',
      cInputProps: {
        ...cInputProps,
        requiredMessage: ' ',
        maxLength: 13,
        type: [InputType.ALL_TEXT],
        width: 120,
        disabled: true,
        validates: [
          // {
          //   message: 'Quantité doit être supérieure à 0',
          //   validateFunction: (value) => isGreatThan(Number(value), 0),
          //   order: 0,
          //   realtime: true,
          // },
          {
            order: 0,
            validateFunction: (value) =>
              isLessThan(Number(value), 10000 * 10000 * 10000 + 1),
            message: 'Max = 1.000.000.000.000',
            realtime: true,
          },
        ],
      },
    },

    {
      label: 'Manquants',
      name: 'manquants_search',
      cInputProps: {
        ...cInputProps,
        width: 150,
        disabled: true,
        invisibleIfChild: true,
        element: (props) => {
          const cdn = props.line?.find((i: any) => i.name === 'cdn')
          const cdnChosen = cdn?.options?.find(
            (item: any) => item.value === cdn.value
          )
          const isError = Number(props.value) > 0
          return (
            <div
              className={clsx(
                'wrapper-table table-cell border-children-cell-table'
              )}
              style={{ borderRight: '1px solid #e5e5e5' }}
            >
              <div className="flex items-center justify-center">
                <p
                  className={clsx(
                    'text-md w-[150px] text-center',
                    isError ? 'font-bold text-red' : ''
                  )}
                >
                  {isError
                    ? `${props.value} ${
                        cdnChosen && cdnChosen.label?.toLowerCase()
                      }${Number(props.value) > 1 ? 's' : ''}`
                    : '-'}
                </p>
              </div>
            </div>
          )
        },
      },
    },

    {
      label: 'Qte confirmée',
      name: 'qte_confirmee_search',
      cInputProps: {
        ...cInputProps,
        width: 120,
        disabled: true,
        textStyleFollowValue: (value) => {
          if (value === '') return ''
          if (Number(value) === 0) return 'font-bold text-red'
          return ''
        },
      },
    },

    {
      label: 'Motif',
      name: 'motif_search',
      cInputProps: {
        ...cInputProps,
        disabled: true,
        cellClass: 'bd-right-cell-table',
        className: 'border-children-cell-table text-center',
      },
    },
    {
      name: 'is_manual_sscc',
      required: false,
      cInputProps: {
        invisible: true,
      },
      isHide: true,
    },
    {
      name: 'is_manual_lot',
      required: false,
      cInputProps: {
        invisible: true,
      },
      isHide: true,
    },
    {
      name: 'is_manual_dluo',
      required: false,
      cInputProps: {
        invisible: true,
      },
      isHide: true,
    },
    {
      name: 'source',
      required: false,
      cInputProps: {
        invisible: true,
      },
      isHide: true,
    },
  ])
  const { cmdResponse } = props
  const [data, setData] = useState<DataTableParam[][]>([])
  const [invalidList, setInvalidList] = useState<InvalidProperty[][]>([[]])
  const [actions, setActions] = useState<ActionParam[]>([])
  const [listNotFillRequired, setListNotFillRequired] = useState<string[][]>([
    [],
  ])
  const [forceShowWarning, setForceShowWarning] = useState<
    ForceShowWarning[][]
  >([[]])
  const [forceShowError, setForceShowError] = useState<ForceShowError[][]>([[]])
  const [disableLines, setDisableLines] = useState<number[]>([])
  const hideCols = [
    'is_manual_sscc',
    'is_manual_lot',
    'is_manual_dluo',
    'source',
  ]

  const [resetFlags, setResetFlags] = useState<boolean[]>([])
  const [observeAndTransform] = useState<ObserveAndTransform[] | undefined>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const [isAllowAddNewLine, setIsAllowAddNewLine] = useState<boolean>(false)
  const [forceReset, setForceReset] = useState<ResetProperty[][]>([[]])
  const [isInit, setIsInit] = useState<boolean>(true)
  const [blockTable] = useState<boolean>(false)
  const [formatRows] = useState<FormatRowCol[][]>([])
  const [photos, setPhotos] = useState<number>(props?.valuePhoto || 0)
  const [currentCDNs, setCurrentCDNs] = useState<Conditionnement[]>([])
  const [listCurrentRef, setListCurrentRef] = useState<EntryFind[]>([])
  const [changedData, setChangedData] = useState<boolean>(false)
  const [isEditing, setIsEditing] = useState<boolean>(false)
  const [isReplenishError, setReplenishError] = useState(false)
  const [searchParams] = useSearchParams()
  const location = useLocation()
  const subpath = location.pathname
  const navigate = useNavigate()
  const pageIndex = Number(searchParams.get('page-index')) || 1
  const pageSize = Number(searchParams.get('page-size')) || 25
  useEffect(() => {
    setPhotos(props?.valuePhoto || 0)
  }, [props?.valuePhoto])
  useEffect(() => {
    const mode = props.mode
    const initData = props?.initData || []
    const dataFromSearch = props?.dataFromSearch || []
    if (mode !== MODE.EDIT) {
      const actions: ActionParam[] = []
      actions.push({
        isAllowDelete: true,
        isAllowEdit: false,
        isFillAllRequired: false,
        isForceValid: false,
        isValid: false,
      })
      setData([firstLineData])
      setActions(actions)
      return
    }
    let data
    if (dataFromSearch.length > 0)
      data = mapOriginDataAndSearchData(initData, dataFromSearch)
    else data = cloneDeep(initData)

    if (isEmpty(data)) data = [cloneDeep(firstLineData)]

    const forceShowErrorClone = [[]]
    const listNotFillRequiredClone = [[]]
    const invalidListClone = [[]]
    const forceResetClone = [[]]
    const forceShowWarningClone = [[]]
    const actionClone = []
    const disabledLinesClone = []

    for (let i = 0; i < data.length - 1; i++) {
      invalidListClone.push([])
      forceShowErrorClone.push([])
      listNotFillRequiredClone.push([])
      forceResetClone.push([])
      forceShowWarningClone.push([])
    }

    if (data.length === 1 && !getValueFromLine('reference', data[0])) {
      actionClone.push({
        isAllowDelete: true,
        isAllowEdit: true,
        isFillAllRequired: false,
        isForceValid: false,
        isValid: false,
      })
    } else
      for (let i = 0; i < data.length; i++) {
        const parentId = getValueFromLine('parent_id', data[i])

        disabledLinesClone.push(i)

        actionClone.push({
          isAllowDelete: !parentId,
          isAllowEdit: !parentId,
          isFillAllRequired: true,
          isForceValid: true,
          isValid: true,
        })
      }

    setIsAllowAddNewLine(!dataIsEmpty(data))

    setForceShowError(forceShowErrorClone)
    setDisableLines(disabledLinesClone)
    setListNotFillRequired(listNotFillRequiredClone)
    setInvalidList(invalidListClone)
    setForceReset(forceResetClone)
    setForceShowWarning(forceShowWarningClone)
    setActions(actionClone)
    setData(data)
    if (props.onChangeData)
      props.onChangeData(
        getLineWithSourceFromDataTable(data, sourceType.MANUAL)
      )
  }, [props?.initData, props?.dataFromSearch, props?.mode])

  useEffect(() => {
    if (!props?.onChangeInit) return
    props?.onChangeInit(isInit)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInit])

  useEffect(() => {
    let headerClone = cloneDeep(header)
    if (props.mode !== MODE.EDIT) {
      const dluoHeaderIndex = getIndexFieldInHeader('dluo', headerClone)
      headerClone[dluoHeaderIndex].cInputProps.element = undefined
      headerClone = removeColsFromHeader(
        ['source', 'qte_confirmee_search', 'motif_search', 'manquants_search'],
        headerClone
      )
      setHeader(headerClone)
      return
    }
    if (hasAtleastDetailLine(data)) {
      addFieldToHeadOfHeader(
        {
          name: 'collapse',
          required: false,
          cInputProps: {
            width: 50,
            invisible: true,
          },
        },
        headerClone
      )
    } else {
      headerClone = removeFieldFromHeader('collapse', headerClone)
    }
    if (data && data.length > 0) {
      const qteConfirmSearch = getValueFromLine('qte_confirmee_search', data[0])
      if (!isEditing) {
        const dluoHeaderIndex = getIndexFieldInHeader('dluo', headerClone)
        if (qteConfirmSearch !== '-') {
          headerClone[dluoHeaderIndex].cInputProps.element = (props) => {
            const source = props.line?.find(
              (i: any) => i.name === 'source'
            )?.value
            const isManualDLUO = props.line?.find(
              (i: any) => i.name === 'is_manual_dluo'
            )?.value
            return (
              <div
                className="wrapper-table table-cell border-children-cell-table"
                style={{ borderRight: '1px solid #e5e5e5' }}
              >
                <div className="flex items-center justify-center">
                  <p
                    className={clsx(
                      'text-md w-[150px] text-center',
                      source === sourceType.MANUAL &&
                        isManualDLUO === '1' &&
                        '!font-semibold',
                      dayjs(props.value, t('date-format')).isBefore(today) &&
                        !cmdResponse?.force_dlou
                        ? '!font-semibold text-red'
                        : ''
                    )}
                  >
                    {props.value || '-'}
                  </p>
                </div>
              </div>
            )
          }
        } else headerClone[dluoHeaderIndex].cInputProps.element = undefined
      } else {
        const dluoHeaderIndex = getIndexFieldInHeader('dluo', headerClone)
        headerClone[dluoHeaderIndex].cInputProps.element = undefined
      }
    }

    setHeader(headerClone)
  }, [data, isEditing, props.mode])
  // add btn

  const onAddNewLine = () => {
    let dataClone = cloneDeep(data)
    let disableLinesClone: number[]
    const actionClone = cloneDeep(actions)
    const forceShowErrorClone = cloneDeep(forceShowError)
    const listNotFillRequiredClone = cloneDeep(listNotFillRequired)
    const forceResetClone = cloneDeep(forceReset)
    const forceShowWarningClone = cloneDeep(forceShowWarning)
    const newData = calcNewLine()

    dataClone.push(newData)

    for (let i = 0; i < actionClone.length; i++) {
      actionClone[i].isAllowEdit = false
      actionClone[i].isAllowDelete = false
    }
    actionClone[actionClone.length - 1].isAllowDelete = false
    actionClone.push({
      isAllowDelete: true,
      isFillAllRequired: false,
      isForceValid: false,
      isValid: false,
      isAllowEdit: true,
    })

    forceShowErrorClone.push([])
    forceResetClone.push([])
    listNotFillRequiredClone.push([])
    forceShowWarningClone.push([])
    resetAllFieldsToDash(
      ['manquants_search', 'qte_confirmee_search', 'motif_search'],
      dataClone
    )
    removeAllDetailLine(dataClone, actionClone)
    disableLinesClone = []
    for (let i = 0; i < dataClone.length - 1; i++) disableLinesClone.push(i)
    disabledFields(
      ['manquants_search', 'qte_confirmee_search', 'motif_search'],
      dataClone[dataClone.length - 1]
    )
    setDisableLines(disableLinesClone)

    setData(dataClone)
    setActions(actionClone)
    setForceShowError(forceShowErrorClone)
    setForceReset(forceResetClone)
    setForceShowWarning(forceShowWarningClone)
    setListNotFillRequired(listNotFillRequiredClone)
    setIsAllowAddNewLine(false)
    setChangedData(true)
    focusById(`commandereference-${actionClone.length - 1}`, 300)
    if (props?.onCheckValid) props.onCheckValid(false)
  }

  useEffect(() => {
    if (actions.length === 0) return
    const actionsClone = cloneDeep(actions)
    for (let i = 0; i < invalidList.length; i++) {
      if (!actionsClone[i]) continue
      if (!invalidList[i]) continue
      actionsClone[i].isValid = invalidList[i].length === 0
    }
    for (let i = 0; i < listNotFillRequired.length; i++) {
      if (!actionsClone[i]) continue
      if (!listNotFillRequired[i]) continue
      actionsClone[i].isFillAllRequired = listNotFillRequired[i].length === 0
    }
    setActions(actionsClone)
  }, [invalidList, listNotFillRequired])

  const calcNewLine = (): DataTableParam[] => {
    let newData = cloneDeep(firstLineData)
    newData.push({ name: 'source', value: 'MANUAL' })
    newData.push({ name: 'ruptures_check_lot', value: '0' })
    newData.push({ name: 'isNewLine', value: '1' })
    return newData
  }

  const onChangeDataTable = async (
    index: number,
    nameAndValue: NameAndValue
  ) => {
    setIsInit(false)
    setChangedData(true)
    setIsEditing(true)
    if (props?.onCheckValid) props.onCheckValid(false)
    const forceResetClone = cloneDeep(forceReset)
    const forceShowWarningClone = cloneDeep(forceShowWarning)
    const onResetInput = (name: string) => {
      const forceResetIndex = forceResetClone[index].findIndex(
        (item) => item.name === name
      )
      const forceShowWarningClone = cloneDeep(forceShowWarning)
      forceShowWarningClone[index] = forceShowWarningClone[index].filter(
        (item) => item.name !== name
      )
      if (forceResetIndex === -1) {
        forceResetClone[index].push({ name: name, value: true })
      } else {
        // console.log(forceResetClone[index])
        forceResetClone[index][forceResetIndex].value =
          !forceResetClone[index][forceResetIndex].value
      }
      const valueIndex = currentLine.findIndex((item) => item.name === name)
      if (valueIndex !== -1) currentLine[valueIndex].value = ''
    }

    const onResetForShowError = (name: string, index: number) => {
      forceShowErrorClone[index] = forceShowErrorClone[index]?.filter(
        (item) => item.name !== name
      )
      setForceShowError(forceShowErrorClone)
    }

    //--------------
    if (props.onBackToInit) props.onBackToInit(false)
    let dataClone = cloneDeep(data)
    // addOrChangeValueInLine({ name: 'reference_id', value: '' }, dataClone[index]);

    let actionsClone = cloneDeep(actions)
    const forceShowErrorClone = cloneDeep(forceShowError)
    let currentLine = dataClone[index]
    let preLine: DataTableParam[] = []

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    if (index > 0) preLine = dataClone[index - 1]

    let currentChange = currentLine.find(
      (item) => item.name === nameAndValue.name
    )
    if (!currentChange) return

    currentChange.value = nameAndValue.value

    currentLine = currentLine.filter((item) => item.name !== nameAndValue.name)
    currentLine.push(currentChange)
    const { name, value } = currentChange

    const refIndex = getIndexes('reference', currentLine)
    const refIdIndex = getIndexes('reference_id', currentLine)

    const lotIndex = getIndexes('lot', currentLine)
    const dluoIndex = getIndexes('dluo', currentLine)
    const ssccIndex = getIndexes('sscc', currentLine)
    const libelleIndex = getIndexes('libelle', currentLine)
    const cdnIndex = getIndexes('cdn', currentLine)
    const statutIndex = getIndexes('statut', currentLine)
    const qteIndex = getIndexes('qte', currentLine)
    const totalIndex = getIndexes('total_piece', currentLine)
    const volumeQteIndex = getIndexes('volume_qte', currentLine)
    const poidsQteIndex = getIndexes('poids_qte', currentLine)
    const volumeSousQteIndex = getIndexes('volume_sousqte', currentLine)
    const poidsSousQteIndex = getIndexes('poids_sousqte', currentLine)
    const sousQteIndex = getIndexes('sous_qte', currentLine)
    const imlIndex = getIndexes('is_manual_lot', currentLine)
    const imsIndex = getIndexes('is_manual_sscc', currentLine)
    const imdIndex = getIndexes('is_manual_dluo', currentLine)
    const rCheckLotIndex = getIndexes('ruptures_check_lot', currentLine)

    function updateCDNRelatedData(newCDN: string, newCdns?: Conditionnement[]) {
      const listCdn = newCdns || currentCDNs
      let currentCDN = listCdn.find((e) => e.id === newCDN)
      if (currentCDN) {
        let volumeQte =
          Number(currentCDN.longueur) *
          Number(currentCDN.largeur) *
          Number(currentCDN.hauteur)
        currentLine[volumeQteIndex].value = volumeQte.toString()
        currentLine[poidsQteIndex].value = currentCDN.poids_brut?.toString()

        if (currentCDN.niveau && currentCDN.niveau <= 1) {
          currentLine[volumeSousQteIndex].value = '0'
          currentLine[poidsSousQteIndex].value = '0'
        } else {
          let lastCurrentCDN = listCdn.find(
            (e) => Number(e.niveau) === Number(currentCDN?.niveau) - 1
          )
          if (lastCurrentCDN) {
            currentLine[sousQteIndex].value = currentCDN.qte?.toString()
            let volumeSousQte =
              Number(lastCurrentCDN.longueur) *
              Number(lastCurrentCDN.largeur) *
              Number(lastCurrentCDN.hauteur)
            currentLine[volumeSousQteIndex].value = volumeSousQte.toString()
            currentLine[poidsSousQteIndex].value =
              lastCurrentCDN.poids_brut?.toString()
          }
        }
      }
    }

    if (name === 'reference') {
      let listCurrentRefClone = cloneDeep(listCurrentRef)
      onResetInput('lot')
      onResetInput('sscc')
      onResetInput('dluo')
      onResetInput('statut')
      onResetInput('qte')
      onResetInput('cdn')
      onResetInput('total_piece')
      onResetForShowError('sscc', index)
      onResetForceShowWaring('dluo', index, forceShowWarningClone)
      onResetForceShowWaring('sscc', index, forceShowWarningClone)
      onResetForceShowWaring('qte', index, forceShowWarningClone)
      setIsLoading(true)

      const referenceDetail = (await referenceApi.findByNum(value || '')).data
        .entry
      setCurrentCDNs(referenceDetail.conditionnement)
      listCurrentRefClone = listCurrentRefClone.filter(
        (item) => item.id !== referenceDetail.id
      )
      listCurrentRefClone.push(referenceDetail)
      setListCurrentRef(listCurrentRefClone)

      onResetForceShowWaring('reference', index, forceShowWarningClone)

      currentLine = addOrReplaceRefId(currentLine, referenceDetail.id)

      currentLine[ssccIndex].disabled =
        currentLine[qteIndex].disabled =
        currentLine[cdnIndex].disabled =
          false
      currentLine[dluoIndex].disabled = !referenceDetail.dluo
      currentLine[lotIndex].disabled = !referenceDetail.gestion

      currentLine[libelleIndex].value = referenceDetail.libelle_long
      currentLine[cdnIndex].value = ''
      currentLine[cdnIndex].options = referenceDetail.conditionnement
        .filter((c) => c.niveau !== 1)
        .map((item) => {
          const result: OptionType = {
            label: item.nom,
            value: item.id,
            extraValue: String(item.qte),
            level: item.niveau,
          }
          return result
        })

      currentLine[statutIndex].value = 'commercialisable'
      currentLine[imdIndex].value = '0'
      currentLine[imsIndex].value = '0'
      currentLine[imlIndex].value = '0'
      updateCDNRelatedData(
        currentLine[cdnIndex]?.value || '',
        referenceDetail.conditionnement
      )
      setForceShowWarning(forceShowWarningClone)
      setForceReset(forceResetClone)
    }

    if (name === 'qte') {
      const cdnValue = currentLine[cdnIndex].value
      if (cdnValue) {
        const ssccValue = currentLine[ssccIndex].value
        if (ssccValue && /^\d+$/.test(ssccValue)) {
          // case user fill sscc before and its valid
          const maxCDN = currentCDNs.find(
            (item) => item.niveau === Define.MAX_CDN_LEVEL
          )
          const cdnChosen = currentCDNs.find(
            (item) => item.id === currentLine[cdnIndex].value
          )
          if (maxCDN) {
            currentLine[totalIndex].value = String(
              Number(value) * (cdnChosen?.qte || 1)
            )
            if (maxQteEditing && Number(value) > maxQteEditing) {
              forceShowWarningClone[index].push({
                name: 'qte',
                message: t('qteExceed'),
              })
            } else {
              forceShowWarningClone[index] = forceShowWarningClone[
                index
              ].filter((item) => item.name !== 'qte')
            }
            setForceShowWarning(forceShowWarningClone)
          }
        } else {
          const options = currentLine[cdnIndex].options
          if (options)
            currentLine[totalIndex].value = String(
              Number(value) * getArticles(options, cdnValue)
            )
        }
      }
    }

    if (name === 'cdn') {
      const ssccValue = currentLine[ssccIndex].value
      if (ssccValue && /^\d+$/.test(ssccValue)) {
        // case user fill sscc before and its valid
        const cdnChosen = currentCDNs.find((item) => item.id === value)
        if (cdnChosen) {
          if (cdnChosen?.niveau === Define.MAX_CDN_LEVEL) {
            const notMaxCDN = currentCDNs.find(
              (item) => item.niveau === Define.MAX_CDN_LEVEL - 1
            )
            currentLine[qteIndex].value = '1'
            currentLine[qteIndex].disabled = true
            currentLine[totalIndex].value = String(
              Number(cdnChosen?.qte) * Number(notMaxCDN?.qte)
            )
          } else {
            const maxCDN = currentCDNs.find(
              (item) => item.niveau === Define.MAX_CDN_LEVEL
            )
            currentLine[qteIndex].value = maxCDN?.qte
              ? String(maxCDN?.qte)
              : '0'
            currentLine[qteIndex].disabled = false
            currentLine[totalIndex].value = String(
              Number(maxCDN?.qte || '0') * Number(cdnChosen?.qte || '0')
            )
          }
        } else {
          const options = currentLine[cdnIndex].options
          if (options && value) {
            currentLine[totalIndex].value = String(getArticles(options, value))
          }
        }
      } else {
        const qteValue = currentLine[qteIndex].value
        if (qteValue) {
          const options = currentLine[cdnIndex].options
          if (options && value) {
            currentLine[totalIndex].value = String(
              Number(qteValue) * getArticles(options, value)
            )
          }
        }
      }
      // update volume and poids
      updateCDNRelatedData(currentChange?.value || '')
      // remove warning of qte
      forceShowWarningClone[index] = forceShowWarningClone[index].filter(
        (item) => item.name !== 'qte'
      )
      setForceShowWarning(forceShowWarningClone)
    }

    if (name === 'sscc') {
      const isManualSsccIndex = getIndexes('is_manual_sscc', currentLine)
      const isManualLotIndex = getIndexes('is_manual_lot', currentLine)
      const isManualDluoIndex = getIndexes('is_manual_dluo', currentLine)
      if (value === '') {
        currentLine[isManualSsccIndex].value = '0'
        currentLine[rCheckLotIndex].value = currentLine[qteIndex].value

        const reference = dataClone[index].find(
          (item) => item.name === 'reference'
        )?.value

        forceShowWarningClone[index] = forceShowWarningClone[index].filter(
          (item) =>
            item.name !== 'sscc' && item.name !== 'dluo' && item.name !== 'qte'
        )
        if (reference) {
          setIsLoading(true)
          const referenceDetail = (
            await referenceApi.findByNum(reference || '')
          ).data.entry
          currentLine[cdnIndex].options = referenceDetail.conditionnement
            .filter((c) => c.niveau !== 1)
            .map((item) => {
              const result: OptionType = {
                label: item.nom,
                value: item.id,
                extraValue: String(item.qte),
                level: item.niveau,
              }
              return result
            })
          setCurrentCDNs(referenceDetail.conditionnement)
          setIsLoading(false)
        }
      }
      if (value && checkSSCC(value)) {
        currentLine[isManualSsccIndex].value = '1'
        currentLine[qteIndex].value = '1'
        currentLine[qteIndex].disabled = true
        setIsLoading(true)
        try {
          const referenceIndex = dataClone[index].findIndex(
            (item) => item.name === 'reference'
          )
          const referenceId = currentLine.find(
            (item) => item.name === 'reference_id'
          )?.value
          const preReference = dataClone[index][referenceIndex].value
          const references = (
            await commandeApi.getReferenceInStockBySscc(value, referenceId)
          ).data.entry
          onResetInput('reference')
          forceShowWarningClone[index] = forceShowWarningClone[index].filter(
            (item) => item.name !== 'qte'
          )
          if (references) {
            const maxCdn = references.find(
              (item) => item.conditionement_level === define.MAX_CDN_LEVEL
            )
            if (maxCdn) setMaxQteEditing(maxCdn?.sous_qte)
            let result =
              references?.length === 1
                ? references[0]
                : selectDefaultReference(references)
            if (!result && references[0]) {
              result = references[0]
            }
            if (result) {
              const {
                qte,
                cdn,
                total_piece,
                lot,
                dluo,
                ref,
                conditionement_id,
                libelle,
                level,
                quantite,
              } = getIn4FromResultOfSSCC(result)

              const referenceDetail = (
                await referenceApi.findByNum(ref.value || '')
              ).data.entry
              setCurrentCDNs(referenceDetail.conditionnement)
              currentLine[refIndex].value = referenceDetail.nom
              currentLine[refIdIndex].value = referenceDetail.id
              currentLine[refIndex].options = [ref]
              const maxCDN = references.find(
                (item) => item.conditionement_level === define.MAX_CDN_LEVEL
              )
              if (
                maxCDN?.sous_qte &&
                maxCDN.sous_qte !== maxCDN.conditionnement?.qte
              ) {
                // If ref palette is not standard
                const cdnChosen = referenceDetail.conditionnement?.find(
                  (item) => item.niveau === define.MAX_CDN_LEVEL - 1
                )
                currentLine[cdnIndex].value = cdnChosen?.id
                currentLine[qteIndex].value = String(result?.sous_qte)
                currentLine[qteIndex].disabled = false
                currentLine[totalIndex].value = String(result?.total_pieses)
                currentLine[cdnIndex].options = referenceDetail.conditionnement
                  .filter((c) => c.niveau === define.MAX_CDN_LEVEL - 1)
                  .map((c) => {
                    return {
                      label: c.nom,
                      value: c.id,
                      level: c.niveau,
                    }
                  })
                const lowerLevelConditionId =
                  referenceDetail.conditionnement.find(
                    (c) => c.niveau === define.MAX_CDN_LEVEL - 1
                  )?.id
                if (lowerLevelConditionId) {
                  updateCDNRelatedData(
                    lowerLevelConditionId,
                    referenceDetail.conditionnement
                  )
                }

                forceShowWarningClone[index].push({
                  name: 'sscc',
                  message: t('refNonStd'),
                })
              } else {
                // If ref palette is standard
                currentLine[cdnIndex].value = String(cdn.value)
                currentLine[qteIndex].value =
                  level === Define.MAX_CDN_LEVEL ? '1' : String(quantite)
                currentLine[qteIndex].disabled = level === Define.MAX_CDN_LEVEL
                currentLine[totalIndex].value =
                  level === Define.MAX_CDN_LEVEL
                    ? String(total_piece)
                    : String(quantite * qte)
                currentLine[cdnIndex].options = referenceDetail.conditionnement
                  .filter((c) => c.niveau !== 1)
                  .map((c) => {
                    return {
                      label: c.nom,
                      value: c.id,
                      level: c.niveau,
                    }
                  })

                updateCDNRelatedData(
                  conditionement_id,
                  referenceDetail.conditionnement
                )

                forceShowWarningClone[index] = forceShowWarningClone[
                  index
                ].filter((error) => error.message !== t('refNonStd'))
              }
              currentLine[libelleIndex].value = libelle || ''
              currentLine[sousQteIndex].value = String(result.sous_qte)
              if (dluo)
                currentLine[dluoIndex].value = moment(
                  (dluo || 0) * 1000
                ).format(t('date-format'))
              if (lot) {
                currentLine[lotIndex].value = String(lot)
                currentLine[isManualLotIndex].value = '0'
              } else currentLine[lotIndex].value = ''
              setWarningForDluo(
                currentLine[dluoIndex].value || '',
                index,
                forceShowWarningClone,
                setForceShowWarning
              )
              if (result.reference_number !== preReference) {
                forceShowWarningClone[index].push({
                  name: 'reference',
                  message: 'Référence mise à jour',
                })
              } else {
                forceShowWarningClone[index] = forceShowWarningClone[
                  index
                ].filter((error) => error.message !== 'Référence mise à jour')
              }

              // if Ref variable is existed
              if (
                result.conditionement_level === define.MAX_CDN_LEVEL &&
                result.conditionnement.qte_variable
              ) {
                currentLine[cdnIndex].options =
                  currentLine[cdnIndex].options?.filter(
                    (item) => item.level === define.MAX_CDN_LEVEL
                  ) || []
                forceShowErrorClone[index].push({
                  name: 'sscc',
                  message: t('refVariable'),
                })
              } else {
                forceShowErrorClone[index] = forceShowErrorClone[index].filter(
                  (error) => error.message !== t('refVariable')
                )
              }
              currentLine[cdnIndex].disabled = false
              currentLine[lotIndex].disabled = true
              currentLine[dluoIndex].disabled = true
              currentLine[isManualDluoIndex].value = '0'
              currentLine[isManualLotIndex].value = '0'
            }
          }

          addOrChangeValueInLine(
            { name: 'ruptures_check_lot', value: '1' },
            currentLine
          )
          forceShowWarningClone[index] = forceShowWarningClone[index].filter(
            (item) => item.message !== 'SSCC inexistant'
          )
          setForceShowWarning(forceShowWarningClone)
          setIsLoading(false)
        } catch (error) {
          console.log('e: ', error)
          setIsLoading(false)
          if (error instanceof AxiosError) {
            if (error?.response?.data?.error?.msg?.includes('found')) {
              forceShowWarningClone[index].push({
                name: 'sscc',
                message: 'SSCC inexistant',
              })
            }
          }

          addOrChangeValueInLine(
            { name: 'ruptures_check_lot', value: '1' },
            currentLine
          )
        }
      }
      if (!checkSSCC(value || '')) {
        setMaxQteEditing(undefined)
        forceShowWarningClone[index] = forceShowWarningClone[index].filter(
          (item) => item.name !== 'sscc'
        )
        forceShowErrorClone[index] = forceShowErrorClone[index].filter(
          (item) => item.name !== 'sscc'
        )
      }
      if (!value) {
        onResetForShowError('sscc', index)
        onResetInput('qte')
        onResetInput('cdn')
        onResetInput('lot')
        onResetInput('dluo')
        onResetInput('total_piece')

        currentLine[qteIndex].disabled =
          currentLine[cdnIndex].disabled =
          currentLine[lotIndex].disabled =
          currentLine[dluoIndex].disabled =
            false
        currentLine[isManualSsccIndex].value = '0'
        currentLine[rCheckLotIndex].value = currentLine[qteIndex].value
      }
      setForceShowWarning(forceShowWarningClone)
      setForceShowError(forceShowErrorClone)
    }

    if (name === 'dluo') {
      setWarningForDluo(
        value || '',
        index,
        forceShowWarningClone,
        setForceShowWarning
      )
      const isManualDluoIndex = getIndexes('is_manual_dluo', currentLine)
      if (value) currentLine[isManualDluoIndex].value = '1'
      else currentLine[isManualDluoIndex].value = '0'
    }
    if (name === 'lot') {
      const isManualLotIndex = getIndexes('is_manual_lot', currentLine)
      if (value) currentLine[isManualLotIndex].value = '1'
      else currentLine[isManualLotIndex].value = '0'
    }
    addOrChangeValueInLine(
      { name: 'source', value: sourceType.MANUAL },
      currentLine
    )
    dataClone[index] = currentLine
    resetAllFieldsToDash(
      ['manquants_search', 'qte_confirmee_search', 'motif_search'],
      dataClone
    )

    setActions(actionsClone)
    setData(dataClone)
    setIsLoading(false)
  }
  const onCheckInvalid = (
    index: number,
    invalidProperty: InvalidProperty | null
  ) => {
    const inValidListClone = cloneDeep(invalidList)
    if (!invalidProperty) inValidListClone[index] = []
    else {
      const indexOfProperty = inValidListClone[index].findIndex(
        (item) => item.name === invalidProperty?.name
      )
      if (indexOfProperty === -1) inValidListClone[index].push(invalidProperty)
      else inValidListClone[index][indexOfProperty] = invalidProperty
    }
    setInvalidList(inValidListClone)
  }

  const onCheckFillRequired = async (index: number, list: string[]) => {
    // const actionsClone = cloneDeep(actions)
    // const listNotFillRequiredClone = cloneDeep(listNotFillRequired)
    // listNotFillRequiredClone[index] = list
    // if (actionsClone[index])
    //   actionsClone[index].isFillAllRequired = list.length === 0
    // await new Promise<void>((resolve) =>
    //   setTimeout(() => {
    //     setListNotFillRequired(listNotFillRequiredClone)
    //     setActions(actionsClone)

    //     resolve()
    //   }, 1)
    // )

    setListNotFillRequired((oldData) => {
      let newData = cloneDeep(oldData)
      newData[index] = list
      return newData
    })
  }

  const onCheckValidByBtn = (index: number) => {
    // prevent valid if there is at error cause ref variable
    if (
      forceShowError[index]?.find((item) => item.message === t('refVariable'))
    ) {
      return
    }
    if (props.mode === MODE.EDIT)
      navigate(
        `${subpath}?page-index=${pageIndex}&page-size=${pageSize}&must-search=false${getCurrentFiltersText(
          ['must-search']
        )}`
      )
    let dataClone = cloneDeep(data)
    let forceShowErrorClone = cloneDeep(forceShowError)
    let actionsClone = cloneDeep(actions)
    let disableLinesClone = cloneDeep(disableLines)
    let forceShowWarningClone = cloneDeep(forceShowWarning)

    if (data?.length > 1) {
      const sscc = getValueFromData(index, 'sscc', data)
      if (sscc) {
        if (checkDupSSccCmdLines(index, data)) {
          forceShowErrorClone = forceError(
            index,
            'sscc',
            'SSCC déjà affecté',
            forceShowErrorClone
          )
          setForceShowError(forceShowErrorClone)
          return
        }
      } else {
        if (checkDluoCmdLinesNotValid(index, data)) {
          forceShowErrorClone = forceError(
            index,
            'dluo',
            'Veuillez vérifier la DLUO saisie',
            forceShowErrorClone
          )
          setForceShowError(forceShowErrorClone)
          return
        }
        if (checkDupEntriesCmdLines(index, data)) {
          forceShowErrorClone = forceError(
            index,
            'reference',
            'Référence déjà affecté',
            forceShowErrorClone
          )
          setForceShowError(forceShowErrorClone)
          return
        }
      }
    }

    forceShowErrorClone[index] = forceShowErrorClone[index].filter(
      (item) => item.name !== 'reference'
    )
    forceShowErrorClone[index] = forceShowErrorClone[index].filter(
      (item) => item.name !== 'sscc'
    )

    onResetForceShowWaring('dluo', index, forceShowWarningClone)
    onResetForceShowWaring('reference', index, forceShowWarningClone)
    onResetForceShowWaring('sscc', index, forceShowWarningClone)

    actionsClone[index].isForceValid = true
    actionsClone = actionsClone.map((item, idx) => {
      const parent_id = getValueFromLine('parent_id', dataClone[idx])
      item.isAllowDelete = !Boolean(parent_id)
      item.isAllowEdit = !Boolean(parent_id)
      item.isFillAllRequired = true
      item.isForceValid = true
      item.isValid = true

      return item
    })
    disableLinesClone.push(index)

    let currentLine = dataClone[index]
    let qteIndex = getIndexes('qte', currentLine)
    let rupturesCheckLotIndex = getIndexes('ruptures_check_lot', currentLine)

    if (qteIndex !== -1 && rupturesCheckLotIndex !== -1) {
      currentLine[rupturesCheckLotIndex].value = currentLine[qteIndex].value
      dataClone[index] = currentLine
    }
    if (changedData) {
      dataClone = removeAllDetailLine2(dataClone)
      resetAllFieldsToDash(
        ['manquants_search', 'qte_confirmee_search', 'motif_search'],
        dataClone
      )
      disableLinesClone = []
      actionsClone = []
      const listNotFillRequiredClone = []
      const invalidListClone = []
      const forceResetClone = []
      forceShowWarningClone = []
      forceShowErrorClone = []

      for (let i = 0; i < dataClone.length; i++) {
        disableLinesClone.push(i)
        actionsClone.push({
          isAllowDelete: true,
          isAllowEdit: true,
          isFillAllRequired: true,
          isForceValid: true,
          isValid: true,
        })
        listNotFillRequiredClone.push([])
        invalidListClone.push([])
        forceResetClone.push([])
        forceShowWarningClone.push([])
        forceShowErrorClone.push([])
      }

      setListNotFillRequired(listNotFillRequiredClone)
      setInvalidList(invalidListClone)
      setForceReset(forceResetClone)
    }
    setData(dataClone)
    if (props?.onChangeData)
      props.onChangeData(
        getLineWithSourceFromDataTable(dataClone, sourceType.MANUAL)
      )
    setForceShowError(forceShowErrorClone)
    setActions(actionsClone)
    setDisableLines(disableLinesClone)
    setIsAllowAddNewLine(true)
    setForceShowWarning(forceShowWarningClone)
    focusById(`commandeicon-edit-${index}`, 500)
    if (props.onCheckValid) props.onCheckValid(true)
    setChangedData(false)
    setIsEditing(false)
  }

  const onAllowEdit = async (index: number) => {
    let disableLinesClone = cloneDeep(disableLines)
    let actionsClone = cloneDeep(actions)
    const dataClone = cloneDeep(data)
    disableLinesClone = disableLinesClone.filter((item) => item !== index)
    actionsClone[index].isForceValid = false
    actionsClone[index].isAllowEdit = true
    for (let i = 0; i < actionsClone.length; i++) {
      if (i !== index) {
        actionsClone[i].isAllowEdit = false
        actionsClone[i].isAllowDelete = false
      }
    }
    const currentLine = dataClone[index]
    if (props?.mode === MODE.EDIT) {
      disabledFields(
        ['motif_search', 'manquants_search', 'qte_confirmee_search'],
        dataClone[index]
      )
      enableField('sscc', currentLine)
    }

    // get list cdns
    try {
      setIsLoading(true)
      const ref = currentLine.find((item) => item.name === 'reference')?.value
      const referenceDetail = (await referenceApi.findByNum(ref || '')).data
        .entry
      const result = referenceDetail.conditionnement
      setCurrentCDNs(result)
      const options: OptionType[] = result
        .filter((c) => c.niveau !== 1)
        .map((item) => {
          const { id, nom, qte, niveau } = item

          const option: OptionType = {
            value: id,
            label: nom,
            extraValue: String(qte),
            level: niveau,
          }
          return option
        })
      const cdnIndex = currentLine.findIndex((item) => item.name === 'cdn')
      currentLine[cdnIndex].options = options
      const currentSSCC = currentLine.find(
        (item) => item.name === 'sscc'
      )?.value
      if (!referenceDetail.dluo) {
        disabledFields(['dluo'], currentLine)
      }
      if (!referenceDetail.gestion) {
        disabledFields(['lot'], currentLine)
      }
      if (currentSSCC) {
        if (
          currentLine[cdnIndex].value ===
          result.find((i) => i.niveau === define.MAX_CDN_LEVEL - 1)?.id
        ) {
          enableField('qte', currentLine)
        }
        const references = (
          await commandeApi.getReferenceInStockBySscc(currentSSCC, ref)
        ).data.entry

        setMaxQteEditing(
          references.find(
            (item) => item.conditionement_level === define.MAX_CDN_LEVEL
          )?.sous_qte
        )
        // disable lot and dluo if It sscc input manually before
        if (
          currentLine.find((item) => item.name === 'is_manual_sscc')?.value ===
            '1' &&
          references
        ) {
          disabledFields(['lot', 'dluo'], dataClone[index])
        }

        // check if ref pallete is update not standard then change from pallete to carton
        if (
          currentLine[cdnIndex].value ===
          result.find((i) => i.niveau === define.MAX_CDN_LEVEL - 1)?.id
        ) {
          const maxCdnLevel = references.find(
            (item) => item.conditionement_level === define.MAX_CDN_LEVEL
          )
          if (
            maxCdnLevel &&
            maxCdnLevel.sous_qte !== maxCdnLevel.conditionnement.qte
          ) {
            currentLine[cdnIndex].options = options.filter(
              (item) => item.level !== define.MAX_CDN_LEVEL
            )
            const level2Cdn = options.find((item) => item.level === 2)?.value
            const qteIndex = currentLine.findIndex(
              (item) => item.name === 'qte'
            )
            currentLine[cdnIndex].value = level2Cdn
            currentLine[qteIndex].disabled = false
            // const maxCDN = result.find(
            //   (item) => item.niveau === Define.MAX_CDN_LEVEL
            // )
            // const totalIndex = currentLine.findIndex(
            //   (item) => item.name === 'total_piece'
            // )
            // const cdnChosen = result.find(
            //   (item) => item.niveau === Define.MAX_CDN_LEVEL - 1
            // )
            // currentLine[qteIndex].value = String(maxCdnLevel?.sous_qte)
            // currentLine[totalIndex].value = String(
            //   Number(maxCdnLevel?.sous_qte) * Number(cdnChosen?.qte)
            // )
            const forceShowWarningClone = cloneDeep(forceShowWarning)
            forceShowWarningClone[index].push({
              name: 'sscc',
              message: t('refNonStd'),
            })
            setForceShowWarning(forceShowWarningClone)
          }
        }
      } else {
        setMaxQteEditing(undefined)
      }
      setCurrentCDNs(result || [])
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
    }

    setData(dataClone)
    setActions(actionsClone)
    setDisableLines(disableLinesClone)
    setIsAllowAddNewLine(false)
    focusById(`commandeicon-valid-${index}`, 500)

    if (props.onCheckValid) props.onCheckValid(false)
    setIsEditing(true)
  }
  const onDeleteLine = (index: number) => {
    deleteLine(index)
  }
  const deleteLine = (index: number) => {
    let dataClone = cloneDeep(data)
    let forceShowErrorClone = cloneDeep(forceShowError)
    let forceShowWarningClone = cloneDeep(forceShowWarning)
    const resetFlagClone = cloneDeep(resetFlags)

    let actionsClone = cloneDeep(actions)
    let disabledLinesClone = cloneDeep(disableLines)
    let invalidListClone = cloneDeep(invalidList)
    let listNotFillRequiredClone = cloneDeep(listNotFillRequired)

    const id = dataClone[index].find((item) => item.name === 'id')?.value
    const isNewLine = getValueFromLine('isNewLine', dataClone[index]) === '1'
    const numberOfManual = getLineWithSourceFromDataTable(
      dataClone,
      sourceType.MANUAL
    ).length
    if (numberOfManual <= 1) {
      dataClone[0] = calcNewLine()
      resetFlagClone[0] = !resetFlagClone[0]
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      actionsClone[0] = {
        isAllowDelete: true,
        isFillAllRequired: false,
        isForceValid: false,
        isValid: false,
        isAllowEdit: false,
        shouldSync: false,
      }
      listNotFillRequiredClone = []
      invalidListClone = [[]]
      forceShowErrorClone = [[]]
      forceShowWarningClone = [[]]
      setIsAllowAddNewLine(false)
      if (props?.onChangeData) props.onChangeData([])

      setIsInit(true)
    } else {
      dataClone.splice(index, 1)
      actionsClone.splice(index, 1)
      listNotFillRequiredClone.splice(index, 1)
      invalidListClone.splice(index, 1)
      actionsClone = setActionsToReadyForEditAndDelete(actionsClone)
      forceShowErrorClone.splice(index, 1)
      forceShowWarningClone.splice(index, 1)
      setIsAllowAddNewLine(true)
    }

    disabledLinesClone = disabledLinesClone.filter((item) => item !== index)
    disabledLinesClone.map((item) => {
      if (item > index) item--
      return item
    })
    if (props?.onDeleteLine && !isNewLine) props.onDeleteLine(id || '')
    if (props?.onCheckValid) props.onCheckValid(true)
    resetAllFieldsToDash(
      ['manquants_search', 'qte_confirmee_search', 'motif_search'],
      dataClone
    )
    removeAllDetailLine(dataClone, actionsClone)
    disabledLinesClone = []
    for (let i = 0; i < dataClone.length; i++) disabledLinesClone.push(i)
    setData(dataClone)
    if (dataClone.length === 1 && !getValueFromLine('id', dataClone[0])) {
      const {
        isAllowDelete,
        isAllowEdit,
        isFillAllRequired,
        isForceValid,
        isValid,
      } = actionsClone[0]
      if (
        !(
          isAllowDelete &&
          isAllowEdit &&
          isFillAllRequired &&
          isForceValid &&
          isValid
        )
      ) {
        if (props?.onChangeData) props.onChangeData([])
        disabledLinesClone = []
      } else if (props?.onChangeData)
        props.onChangeData(
          getLineWithSourceFromDataTable(dataClone, sourceType.MANUAL)
        )
    } else if (props?.onChangeData)
      props.onChangeData(
        getLineWithSourceFromDataTable(dataClone, sourceType.MANUAL)
      )
    setForceShowWarning(forceShowWarningClone)
    setForceShowError(forceShowErrorClone)
    setResetFlags(resetFlagClone)
    setDisableLines(disabledLinesClone)
    setActions(actionsClone)
    setInvalidList(invalidListClone)
    setListNotFillRequired(listNotFillRequiredClone)
  }

  const onChangePhotos = (value: number) => {
    setPhotos(value)
    props.onChangePhotos(value)
  }

  const onSearchStock = async () => {
    if (props?.onSearchStock) {
      await props.onSearchStock()
    }
  }

  function resetSearch() {
    setReplenishError(false)
    onSearchStock()
  }

  const onChangeForceDluo = (value: boolean) => {
    let dataClone = cloneDeep(data)
    let actionsClone = cloneDeep(actions)
    let disabledLinesClone: number[]

    resetAllFieldsToDash(
      ['manquants_search', 'qte_confirmee_search', 'motif_search'],
      dataClone
    )
    removeAllDetailLine(dataClone, actionsClone)
    disabledLinesClone = []
    for (let i = 0; i < dataClone.length; i++) {
      const currentAction = actionsClone[i]

      const {
        isAllowDelete,
        isAllowEdit,
        isForceValid,
        isFillAllRequired,
        isValid,
      } = currentAction
      if (isForceValid && isFillAllRequired && isValid)
        disabledLinesClone.push(i)
    }
    setData(dataClone)
    if (dataClone.length === 1 && !getValueFromLine('id', dataClone[0])) {
      if (props?.onChangeData) props.onChangeData([])
      disabledLinesClone = []
    }

    setActions(actionsClone)
    setDisableLines(disabledLinesClone)
    if (props?.onChangeData)
      props.onChangeData(
        getLineWithSourceFromDataTable(dataClone, sourceType.MANUAL)
      )
    if (props.onChangeForcerDluo) props.onChangeForcerDluo(value)
  }
  // check if there is at least one auto line that has level = 2 and status is reappro mission
  const canReplenish =
    !props?.isSearch && !isLoading
      ? data.find((item) => {
          const level = item.find(
            (i) => i.name === 'conditionement_level'
          )?.value
          if (Number(level) === Define.MAX_CDN_LEVEL - 1) {
            const sscc = item.find((i) => i.name === 'sscc')?.value
            if (sscc === t('reappro_mission')) {
              return true
            }
          }
          return false
        })
      : false

  function sendReplenishMission() {
    setIsLoading(true)
    commandeApi
      .replenishMission({
        entry: {
          client_code_nom: cmdResponse?.client_code_nom,
          commande_id: cmdResponse?.id,
          commande_nom: cmdResponse?.nom,
          company_code_nom: cmdResponse?.company_code_nom,
          warehouse_code_nom: cmdResponse?.warehouse_code_nom,
          priorite: cmdResponse?.priorite,
          ssccs:
            cmdResponse?.com_article
              // filter auto line that has level = 2 and status is reappro mission
              ?.filter((item) => {
                return (
                  item.conditionement_level === Define.MAX_CDN_LEVEL - 1 &&
                  item.info_action === InfoActionType.REAPPRO_MISSION
                )
              })
              // map array into list of their sscc value
              .map((item) => {
                return {
                  sscc: item.sscc || '',
                  is_manual_dluo: Boolean(item.is_manual_dluo),
                  is_manual_lot: Boolean(item.is_manual_lot),
                  is_manual_sscc: Boolean(item.is_manual_sscc),
                  dluo: item.dluo || 0,
                  lot: item.lot || '',
                }
              }) || [],
        },
      })
      .then((res) => {
        if (res.data?.err_detail?.length) {
          setReplenishError(true)
        } else {
          // case success
          resetSearch()
        }
      })
      .catch((e) => {
        console.log(e)
        setReplenishError(true)
      })
      .finally(() => setIsLoading(false))
  }

  return (
    <SectionLayout
      title="Détail"
      action={
        <Action
          photos={photos}
          onChangePhotos={onChangePhotos}
          isAllowAddNewLine={isAllowAddNewLine}
          onClickPlusBtn={onAddNewLine}
          detailLength={data.length}
          canReplenish={!!canReplenish}
          sendReplenishMission={
            props.mode === MODE.EDIT ? sendReplenishMission : undefined
          }
        />
      }
      leftAction={
        <Row className="ml-4 h-10" align="middle">
          <Col className="h-8">
            <Form.Item label="" name="force_dlou">
              <CSwitch
                id={commandeKey['Forcer-la-DLUO']}
                previousId={commandeKey['priorite']}
                nextId={commandeKey['photos']}
                className=""
                checked={
                  props.mode === MODE.EDIT
                    ? cmdResponse?.force_dlou
                    : props.forcerDluo
                }
                defaultChecked={false}
                onChange={onChangeForceDluo}
              />
            </Form.Item>
          </Col>
          <Col className="h-10 flex align-middle">
            <span className="text-base p-1 text-[#20458F] flex items-center">
              Forcer la DLUO
            </span>
          </Col>
          {props.mode === MODE.EDIT && (
            <Col className="h-10 ml-4 flex item-center">
              <button
                className="text-xs cursor-pointer border-none rounded-md bg-[#20458F] text-white hover:text-white flex items-center px-2 font-semibold"
                onClick={onSearchStock}
              >
                <span>
                  <img src={SearchIcon} alt="" />
                </span>
                Recherche au stock
              </button>
            </Col>
          )}
        </Row>
      }
      alert={props?.alert}
    >
      <Spin spinning={isLoading || Boolean(props?.isSearch)}>
        <div className="overflow-y-hidden pb-2 detail-scroll box-input-font-size-bigger">
          <ITable
            name="commande"
            previousId={commandeKey['photos']}
            blockTable={blockTable}
            resetFlags={resetFlags}
            formatCols={formatCols}
            formatRows={formatRows}
            header={header}
            data={data}
            actions={actions}
            disabledLines={disableLines}
            onChangeDataTable={onChangeDataTable}
            onCheckInvalid={onCheckInvalid}
            onCheckFillRequired={onCheckFillRequired}
            forceShowError={forceShowError}
            forceShowWarning={forceShowWarning}
            hideCols={hideCols}
            observeAndTransform={observeAndTransform}
            forceReset={forceReset}
            inTwoGradeTable={
              props.mode === MODE.EDIT && hasAtleastDetailLine(data)
            }
            lineClass={getLineClass}
            onRefresh={() => {}}
            actionStyle=""
            actionNode={(
              index: number,
              actionParam?: ActionParam
            ): ReactNode => {
              // if (props.mode === MODE.VIEW) return false
              const {
                isValid = false,
                isFillAllRequired = false,
                isAllowDelete = true,
                isForceValid = false,
                isAllowEdit = true,
              } = actionParam || {
                isValid: false,
                isFillAllRequired: false,
                isAllowDelete: true,
                isForceValid: false,
                isAllowEdit: true,
              }
              // console.log({isFillAllRequired,isValid})
              return (
                <div>
                  <Row gutter={8} align="middle">
                    <Col span={12}>
                      {(!isFillAllRequired || !isValid) && (
                        <i className="icon-valid-disable"></i>
                      )}
                      {isFillAllRequired && isValid && !isForceValid && (
                        <i
                          onClick={() => onCheckValidByBtn(index)}
                          className="icon-valid focus:rounded-2xl"
                          data-previous-id={`commandeqte-${index}`}
                          data-next-id={`commandeicon-delete-${index}`}
                          id={`commandeicon-valid-${index}`}
                          tabIndex={0}
                        />
                      )}
                      {isFillAllRequired &&
                        isValid &&
                        isForceValid &&
                        isAllowEdit && (
                          <i
                            className="icon-edit"
                            data-previous-id={`commandeqte-${index}, commandeicon-delete-${
                              index - 1
                            }, ${commandeKey['photos']}`}
                            data-next-id={`commandeicon-delete-${index}`}
                            id={`commandeicon-edit-${index}`}
                            tabIndex={0}
                            onClick={() => onAllowEdit(index)}
                          />
                        )}
                      {isFillAllRequired &&
                        isValid &&
                        isForceValid &&
                        !isAllowEdit && <i className="icon-edit-disable" />}
                    </Col>
                    <Col span={12}>
                      {isAllowDelete && (
                        <Popconfirm
                          zIndex={Infinity}
                          icon={null}
                          title="Êtes-vous sûr de vouloir supprimer cet élément?"
                          description=""
                          okText="Supprimer"
                          okButtonProps={{
                            className: 'ok-btn',
                          }}
                          cancelButtonProps={{
                            className: 'cancel-btn',
                          }}
                          cancelText="Annuler"
                          onConfirm={() => {
                            onDeleteLine(index)
                            enabledAllModal()
                          }}
                          onCancel={() =>
                            focusById(`commandeicon-delete-${index}`)
                          }
                          afterOpenChange={(visible) => {
                            if (visible) disabledAllModal()
                            else enabledAllModal()
                          }}
                        >
                          <i
                            className="icon-delete focus:rounded-2xl"
                            tabIndex={0}
                            id={`commandeicon-delete-${index}`}
                            data-previous-id={`commandeicon-valid-${index}, commandeicon-edit-${index}, commandeqte-${index}, commandestatut-${index}`}
                            data-next-id={`commandereference-${
                              index + 1
                            }, commandeicon-edit-${index + 1}, ${
                              commandeKey['code-nom-transporter']
                            }, ${commandeKey['code-nom-destinataire']}, ${
                              commandeKey['preparation-commentaire']
                            }, ${commandeKey['Sauvegarder']}`}
                          ></i>
                        </Popconfirm>
                      )}
                      {!isAllowDelete && (
                        <i className="icon-delete-disable"></i>
                      )}
                    </Col>
                  </Row>
                </div>
              )
            }}
          />
        </div>
      </Spin>
      <Modal
        title=""
        open={isReplenishError}
        width={900}
        onCancel={resetSearch}
        style={{ zIndex: 100 }}
        closable={true}
        centered
        data-testid="custom-popup"
        footer={
          <button
            onClick={() => {
              setReplenishError(false)
              resetSearch()
            }}
            className="border-none bg-[#01E37A] text-white hover:text-white text-lg font-semibold  px-5 py-1 rounded-lg cursor-pointer relative"
          >
            {t('validate')}
          </button>
        }
      >
        <div className="bd-bot-page-title pb-3.5" style={{ height: '10%' }}>
          <span className="modal-title-heading-1 mr-1">
            {t('replenishFail')}
          </span>
        </div>
        <p className="text-lg text-[#505050] my-10">{t('searchStockAgain')}</p>
      </Modal>
    </SectionLayout>
  )
}

export default DetailSection
