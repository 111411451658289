export const checkSSCC = (num: string): boolean => {
  const length = num.trim().length
  if (length !== 18) return false

  return checkDigit(num)
}

const checkDigit = (num: string): boolean => {
  const length = num.trim().length
  //hadnle length of Num
  if (length === 0) return false

  //Handle else
  let sum = 0
  for (let i = 0; i < num.trim().length - 1; i++) {
    let mul = i % 2 === 0 ? 3 : 1
    const c = num[i]
    sum += Number(c) * mul
  }

  const r = sum % 10
  const lastDigit = Number(num[length - 1])
  if (r === 0) return lastDigit === 0

  return 10 - r === lastDigit
}
